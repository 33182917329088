<template>
  <div style="margin-top: 20px;height: calc(100% - 20px)">
    <a-row type="flex" justify="center" style="height: 100%">
      <a-col :span="20" style="display: flex;justify-content: center;align-items: center;height: 100%">
        <el-result icon="warning">
          <template #title>
            <span style="color: white;font-size: 30px">页面不存在</span>
          </template>
          <template #extra>
            <a-button @click="$router.replace('/index')" style="height: 40px;margin-top: 10px" size="large" class="hoverButton" ghost block>返回首页</a-button>
          </template>
        </el-result>
      </a-col>
    </a-row>
 </div>
</template>

<script>

export default {
  name: "notFound",
  props: {

  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
/* 按钮鼠标移入聚焦 */
.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}
</style>